import {Component, OnDestroy, AfterViewInit, Output, EventEmitter, ElementRef, Input} from '@angular/core';

@Component({
  selector: 'ngx-tiny-mce',
  template: '',
})
export class TinyMCEComponent implements OnDestroy, AfterViewInit {

  @Output() editorChange = new EventEmitter<any>();
  @Input() initialContent = '';

  editor: any;

  constructor(private host: ElementRef) { }

  ngAfterViewInit() {
    const content = this.initialContent || '';
    tinymce.init({
      target: this.host.nativeElement,
      plugins: ['link', 'paste', 'table', 'lists'],
      skin_url: 'assets/skins/lightgray',
      setup: editor => {
        this.editor = editor;
        editor.on('init', () => {
          editor.setContent(content);
        });
        editor.on('change', () => {
          this.editorChange.emit(editor.getContent());
        });
      },
      height: '320',
      width: '100%',
      menubar: false,
      toolbar1: "undo redo | bold italic underline strikethrough | bullist numlist | blockquote removeformat | link unlink | table",
    });
  }

  ngOnDestroy() {
    tinymce.remove(this.editor);
  }
}
