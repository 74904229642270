import { Component, Input, OnInit } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { UserData } from '../../../@core/data/users';
import { AnalyticsService } from '../../../@core/utils';
import { LayoutService } from '../../../@core/utils';
import {NbAuthJWTToken, NbAuthService} from "@nebular/auth";
import { filter, map } from 'rxjs/operators';
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  @Input() position = 'normal';

  user: any;
  env = environment;
  userMenu = [{ title: 'Abmelden' }];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private userService: UserData,
              private analyticsService: AnalyticsService,
              private layoutService: LayoutService,
              private authService: NbAuthService,
              public router: Router,)
  {

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        console.log(token);
        if (token.isValid()) {
          this.user = token.getPayload();
        }

      });

  }

  ngOnInit() {
    this.menuService.onItemClick()
      .subscribe((title) => {
        console.log(title);
        if (title.item.title === 'Abmelden') {

          this.router.navigateByUrl('auth/logout');
        }
      });
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }
}
