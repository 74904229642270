import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {take} from 'rxjs/operators';
import {AngularFireAuth} from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  public user: any;
  public userGroup: any;

  constructor(
    private firestore: AngularFirestore,
    public fireauth: AngularFireAuth,
  ) { }

  getUser(id: string) {
    return new Promise((resolve, reject) => {
      if (this.user && this.user.id === id) {
        resolve(this.user);
      } else {
        this.firestore.collection('users').doc(id).get().pipe(take(1))
          .subscribe((userSnapshot) => {
            const userData: any = userSnapshot.data();
            userData.id = userSnapshot.id;
            this.user = userData;
            console.log('get user');
            console.log(this.user);
            resolve(this.user);
          }, reject);
      }
    });
  }

  getUserGroup(group: string) {
    return new Promise((resolve, reject) => {
      if (this.userGroup && this.userGroup.id === group) {
        resolve(this.userGroup);
      } else {
        return this.firestore.collection('licenseGroup').doc(group).get().pipe(take(1))
          .subscribe((groupSnapshot) => {
            const groupData: any = groupSnapshot.data();
            groupData.id = groupSnapshot.id;
            this.userGroup = groupData;
            console.log('get user');
            console.log(this.userGroup);
            resolve(this.userGroup);
          }, reject);
      }
    });
  }

  getUsers() {
    return this.firestore.collection('users');
  }

  getGroupUsers() {
    return this.firestore.collection('users', (ref) => {
      return ref.where('group', '==', this.user.group);
    });
  }

  setRole(userId: string, role: string) {
    return this.firestore.collection('users').doc(userId).update({ role: role });
  }

  deleteUser(userId: string) {
    return this.firestore.collection('users').doc(userId).delete();
  }
}
