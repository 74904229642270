import {Injectable} from "@angular/core";
import {UserService} from "./services/user.service";

@Injectable()
export class AppConfigService {

  public version: string;
  public apiEndpointSomeData: string;

  constructor(
    private userService: UserService
  ) {}

  load()  {
    return new Promise(async(resolve, reject) => {
      this.userService.fireauth.auth.onAuthStateChanged(async (user) => {
        if (user) {
          console.log('getting user');
          await this.userService.getUser(user.uid);
          if (this.userService.user.group) {
            await this.userService.getUserGroup(this.userService.user.group);
          }
          console.log('after getting user');
        } else {
          this.userService.user = null;
          this.userService.userGroup = null;
        }
        resolve ();
      }, reject);
    });
  }
}
