import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators';
import {UserService} from '../services/user.service';

@Injectable()
export class AuthGuard implements CanActivate {

  targetUrl = '';

  constructor(
    private authService: NbAuthService,
    private router: Router,
    private userService: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    this.targetUrl = route.routeConfig.path;
    return this.authService.isAuthenticated()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['auth/login']);
          } else {
            const user = this.userService.user;
            if (user.role !== 'globalAdmin') {
              if (user.role === 'groupAdmin') {
                if (this.isGroupAdminRoute()) {
                  return;
                } else {
                  this.router.navigate(['pages/group']);
                }
              } else {
                this.authService.logout('email').subscribe(() => {});
                this.router.navigate(['auth/login']);
              }
            }
          }
        }),
      );
  }

  isGroupAdminRoute() {
    if (this.targetUrl === 'group-questions' ||
      this.targetUrl === 'group' ||
      this.targetUrl === 'group-knowledge' ||
      this.targetUrl === 'group-departure' ||
      this.targetUrl === 'report' ||
      this.targetUrl === 'group-user' ||
      this.targetUrl.startsWith('report/knowledge')
    ) {
      return true;
    } else {
      return false;
    }
  }
}
